const data = [
  {
    responsive_id: '',
    id: 95,
    full_name: 'Edwina Ebsworth',
    campaign: 'Saving for Wedding',
    amount: '10,000 XAF',
    status: 'Withdrawal',
    validation: false,
    date_time: 'Wednesday, 5 Jan. 2022 - 11:13 PM',
  },
  {
    responsive_id: '',
    id: 1,
    full_name: "Korrie O'Crevy",
    campaign: 'Nuclear Power Project',
    amount: '10,000,000 XAF',
    status: 'Cancelled',
    validation: false,
    date_time: 'Wednesday, 5 Jan. 2021 - 11:03 AM',
  },
  {
    responsive_id: '',
    id: 7,
    full_name: "Falloon O'De",
    campaign: 'Saving the Environmental',
    amount: '500,000 XAF',
    status: 'Deposit',
    validation: true,
    date_time: 'Wednesday, 5 Jan. 2021 - 10:13 AM',
  },
  {
    responsive_id: '',
    id: 3,
    full_name: 'Stella Ganderton',
    campaign: 'New Business Center',
    amount: '35,000 XAF',
    status: 'Deposit',
    validation: true,
    date_time: 'Wednesday, 5 Jan. 2021 - 11:00 AM',
  },
  {
    responsive_id: '',
    id: 4,
    full_name: 'Dorolice Crossman',
    campaign: 'School Fees',
    amount: '17,000 XAF',
    status: 'Deposit',
    validation: true,
    date_time: 'Friday, 5 Jan. 2021 - 09:13 PM',
  },
]

export default data
